<template>
  <b-row>
    <b-col sm="12">

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Callback</h4>
            <div class="small text-muted">Extensión de funcionalidades de diferentes métodos del sistema</div>
          </b-col>                             
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    ref="table"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"                    
                    selectable
                    select-mode="single"                    
                    v-if="table.items.length">                       

                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template>

                <template v-slot:cell(blocked)="data">
                  <b-icon icon="lock" v-if="data.item.blocked" title="Bloqueado"/>
                </template>

                <template v-slot:cell(name)="data">                       
                  {{data.item.name}}                  
                </template> 
               
                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations">
                    <div v-if="data.item.observations.length >50" :title="data.item.observations">
                      <b-icon icon="card-text" class="mr-1" style="font-size:17px;"></b-icon>
                      {{data.item.observations.substr(0, 150)}}
                    </div>
                    <div v-else>                      
                      {{data.item.observations}}
                    </div>
                  </div>
                </template>                                        

                <template v-slot:cell(log_url)="data">                                                           
                  <b-link :href="getLogUrl(data.item)" target="_blank">Ver</b-link>
                </template>   

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="openCode(data.item)">
                      <b-icon icon="code-slash"></b-icon> Código
                    </b-dropdown-item>     

                    <b-dropdown-header>Log</b-dropdown-header> 
                    <b-dropdown-item @click="openLog(data.item)">
                      <b-icon icon="bar-chart-steps"></b-icon> Ver
                    </b-dropdown-item>  
                    <b-dropdown-item @click="deleteLog(data.item)">
                      <b-icon icon="x"></b-icon> Vaciar
                    </b-dropdown-item>  

                    <b-dropdown-header>Protección</b-dropdown-header> 
                    <b-dropdown-item @click="blockedPHP(data.item)" v-if="!data.item.blocked">
                      <b-icon icon="lock-fill"></b-icon> Bloquear
                    </b-dropdown-item>         
                    <b-dropdown-item @click="unlockedPHP(data.item)" v-else>
                      <b-icon icon="lock-fill"></b-icon> Desbloquear
                    </b-dropdown-item>                             
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>          
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import moment from 'moment'
    
  export default {    
    data: () => {
      return {      
        access: {
          module_id: Modules.PHP,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPHPCallback',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true},       
            {key: 'blocked', label: 'Bloqueado', class: 'text-center'},                 
            {key: 'name', label: 'Nombre'},
            {key: 'observations', label: 'Observaciones'},                   
            {key: 'log_url', label: 'LOG'},                 
            {key: 'f_action', label:''},
          ],
        },
        constant: {
          type: 'callback'
        },        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },     
      load() {
        var result = serviceAPI.obtenerPHP(this.constant.type)

        result.then((response) => {
          var data = response.data
          this.table.items = data      

          this.table.items.forEach(element => {                        
            if(!element.blocked) {
              element._rowVariant = 'danger'
            }            
          });    
          
          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }               
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      getLogUrl(item) {
        return item.log_url + Session.getToken()         
      },      
      openCode(item) {
        this.$router.push({ name: 'PHPStaffCrudPHPCode', params: {id: item.id}  })
      },
      blockedPHP(item) {
        this.$bvModal.msgBoxConfirm('¿Desea bloquear el bloque de código (' + item.name + ')?', {
          title: 'Bloquear código',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'secondary',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'secondary',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {         
            let loader = this.$loading.show();

            var params = {
              id: item.id,
              blocked: true
            }
            
            var result = serviceAPI.guardarBlockedPHP(params);
                    
            result.then((response) => {                    
              loader.hide()
              this.load()
              this.$awn.success("Código bloqueado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })                  
      },
      unlockedPHP(item) {
        this.$bvModal.msgBoxConfirm('¿Desea desbloquear el bloque de código (' + item.name + ')?', {
          title: 'Desbloquear código',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'secondary',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'secondary',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {              
            let loader = this.$loading.show();

            var params = {
              id: item.id,
              blocked: false
            }
            
            var result = serviceAPI.guardarBlockedPHP(params);
                    
            result.then((response) => {                    
              loader.hide()
              this.load()
              this.$awn.success("Código desbloqueado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })                    
      },      
        
      openLog(item) {
        const url = this.getLogUrl(item);
        window.open(url, '_blank');        
      },
      deleteLog(item) {        
        var result = serviceAPI.eliminarPHPLog(item.id);

        result.then((response) => {                
          console.log(response)
          this.$awn.success(response.data);
        })
        .catch(error => {     
          console.log(error)     
          this.$awn.alert(Error.showError(error));
        })        
      },  
    }      
  }
</script>
<style>

</style>